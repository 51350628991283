import React from 'react'
import { loadStripe } from "@stripe/stripe-js/pure"
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm"
import { useStaticQuery, graphql } from "gatsby"



export const PaymentComponent = (props) => {


  const data = useStaticQuery(
    graphql`
      query {
        site {
              siteMetadata {
                stripe_publishable_key
              }
            }
      }
    `
  )
  
  const promise = loadStripe(data.site.siteMetadata.stripe_publishable_key);

  
  return (
    <Elements stripe={promise}>
        <CheckoutForm  
        action={props.action} 
        priceId={props.priceId} 
        customerId={props.customerId} 
        setResponse={props.setResponse}
        setProcessing={props.setProcessing}
        // billingPeriod={props.billingPeriod}
        />
    </Elements>
  )
}
