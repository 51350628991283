import React from "react"
import { H4, GrayText } from "../../elements/shared/texts"
import {  NakedPrimaryButton } from "../../elements/shared/buttons"
import styled  from "styled-components"
import tw from "tailwind.macro"
import { IsBrowser } from "../../helpers/utils"
import { navigate } from "@reach/router"
import { GlobalActionsType } from "../../utils/constants"
import { UseGlobalDispatchContext } from "../../context/globalContext"
import AuthenticationService from "../../web_service/AuthenticationService"
import Skeleton from "react-loading-skeleton"

const Container = styled.div`
  background-color: ${props => props.theme.colors.deepBackground};
  padding: 20px;
  margin-bottom: 30px;
  border: ${props => props.theme.measurements.borderWidth} solid
    ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.measurements.borderRadius};

  div {
    padding: 10px 0;
    ${tw`flex flex-col justify-start`}
  }

  #update-password-link {
    ${tw`w-full  flex  justify-start flex-col`}
    button {
      display: flex;
    }
  }
`

export const PersonalDetails = props => {
  const dispatch = UseGlobalDispatchContext()

  const signOut = () => {
    AuthenticationService.getService().logout(() => {})
    dispatch({ type: GlobalActionsType.USER, userData: false })
    if (IsBrowser) {
      navigate("/auth")
    }
  }

  return (
    <div>
      <H4 className={"py-2"}>Personal details</H4>

      <Container>
        <p>
          Thank you for your continued support. Here is your account
          information.
        </p>
        <div>
          <small>Full name</small>
          {
            props.isLoadingUser ? <Skeleton width={100}/> : <strong>
                {props.firstName} {props.lastName}
              </strong>
          }
        </div>

        <div>
          <small>Email</small>
          {
            props.isLoadingUser ? <Skeleton width={100}/> : <strong>{props.email}</strong>
          }

        </div>
        <div id="update-password-link">
          <GrayText>
            <small>
              To update your password, you will first need to log out.
            </small>
          </GrayText>
          {
            props.isLoadingUser ? <Skeleton width={200}/> : <NakedPrimaryButton onClick={signOut}>
                Log out to update the password?
              </NakedPrimaryButton>
          }
        </div>
      </Container>
    </div>
  )
}

