import StorageService from "../../helpers/StorageService"
import AuthenticationService from "../../web_service/AuthenticationService"
import { UseDashboardDispatchContext } from "../../context/dashboardContext"
import {
  DashboardActions,

} from "../../utils/constants"
import { A, H4 } from "../../elements/shared/texts"
import {
  Filled_A,
  PrimaryButton,
} from "../../elements/shared/buttons"
import { convertTimestampToDate } from "../../helpers/utils"
import React from "react"
import {
  AccountBillingContainer,
  PaymentContainer,
} from "./AccountBillingContainer"
import CreditCardComponent from "../CreditCardComponent"
import { FiExternalLink } from "react-icons/fi"
import Skeleton from "react-loading-skeleton"

export const AccountDetails = props => {
  const storage = StorageService.getService()
  const auth = AuthenticationService.getService()
  const dashboardDispatch = UseDashboardDispatchContext()

  const fetchPortalSessions = async () => {
    dashboardDispatch({
      type: DashboardActions.MANAGE_SUBSCRIPTION,
      session: { loading: true, data: {}, error: null },
    })
    const data = {
      cId: storage.getUser().stripeId,
    }

    const session = await auth.fetchPortalSession(data)
    if (session) {
      dashboardDispatch({
        type: DashboardActions.MANAGE_SUBSCRIPTION,
        session: { loading: false, data: session, error: null },
      })
    } else {
      dashboardDispatch({
        type: DashboardActions.MANAGE_SUBSCRIPTION,
        session: {
          loading: false,
          data: {},
          error: "Unable to fetch portal session",
        },
      })
    }
  }

  const onPrimaryBtnClicked = e => {
    if (!props.isPremium) {
      props.setAction(DashboardActions.SUBSCRIBE)
    } else {
      fetchPortalSessions()
    }
  }

  return (
    <div>
      <H4 className={"py-2"}>Account billing</H4>
      <div className="mb-8">
        <AccountBillingContainer isCanceled={props.isCancelled}>
          <div>
            <div>
              <small>Current plan</small>
              {props.isLoadingUser ? (
                <Skeleton width={100} />
              ) : (
                <span>
                  <strong>{props.isPremium ? "SUPPORT" : "FREE"} </strong>{" "}
                  <sup>{props.isCancelled ? "Canceled" : "Active"}</sup>
                </span>
              )}

              {props.isLoadingUser ? (
                <Skeleton count={2} />
              ) : (
                <p className="mt-2">
                  {props.isPremium
                    ? "Thank you for supporting my work!"
                    : "You are currently not supporting, you can do so by clicking the (Support Us) button"}
                </p>
              )}
            </div>
            <div>
              {
                props.isLoadingUser ? <Skeleton height={150} width={250}/> :
                  <>
                    {props.isPremium && <CreditCardComponent card={props.card} />}
                  </>
              }


              {
                props.isLoadingUser ? <Skeleton /> :
                  <span>
                    {props.isPremium && (
                      <div className="col-span-2">
                        {props.cancelAtPeriodEnd ? (
                          <small>
                            Canceled on{" "}
                            {convertTimestampToDate(props.nextPaymentDate)}
                          </small>
                        ) : (
                          <small>
                            Your next support payment will be on{" "}
                            {convertTimestampToDate(props.nextPaymentDate)}
                          </small>
                        )}
                      </div>
                    )}
                  </span>
              }
            </div>
            {props.isPremium && (
              <div className={"mt-4"}>
                <strong className={"py-2"}>Latest payments</strong>

                {props.isLoadingUser ? (
                  <Skeleton count={4} />
                ) : (
                  <span>
                    {props.latestPayments &&
                      props.latestPayments.map(invoice => (
                        <PaymentContainer
                          className={"flex justify-between"}
                          isPaid={invoice.status === "paid"}
                        >
                          <small>
                            {" "}
                            {convertTimestampToDate(invoice.createdAt)}
                          </small>
                          <small>
                            {" "}
                            $
                            {invoice.status === "paid"
                              ? invoice.amountPaid / 100
                              : invoice.amountDue / 100}{" "}
                          </small>
                          <small className={"flex items-center"}>
                            <A href={invoice.invoiceUrl} target={"_blank"}>
                              {invoice.status === "paid"
                                ? "Paid (download invoice)"
                                : "Failed (action required)"}
                              <FiExternalLink />
                            </A>
                          </small>
                        </PaymentContainer>
                      ))}
                  </span>
                )}
              </div>
            )}
          </div>

          {props.isLoadingUser ? (
            <Skeleton width={200} height={40} className={"my-4"} />
          ) : (
            <span>
              {props.user.subscription.status === "paused" ? (
                <Filled_A
                  href={props.user.billingState.invoiceUrl}
                  target={"_blank"}
                >
                  Resume your support
                </Filled_A>
              ) : (
                <PrimaryButton
                  onClick={onPrimaryBtnClicked}
                  className="w-full my-6"
                >
                  {props.isPremium ? (
                    <span>Manage your billing</span>
                  ) : (
                    <span>Support Us</span>
                  )}
                </PrimaryButton>
              )}
            </span>
          )}
        </AccountBillingContainer>
      </div>
    </div>
  )
}
