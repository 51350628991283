import React from "react"
import { BannerContainer } from "./BannerContainer"
import { H4 } from "../../elements/shared/texts"
import { BsExclamationTriangle } from "react-icons/bs"
import { Filled_A } from "../../elements/shared/buttons"
import Skeleton from "react-loading-skeleton"

export const BillingErrorBanner = props => {
  if (props.billingState.hasSucceeded || !props.billingState.invoiceUrl) {
    return null
  }

    return (
      <BannerContainer>
        {
          props.isLoadingUser ? <Skeleton height={30} width={250} className={"my-6"}/> :

            <H4 className={"flex items-center"}>
              <BsExclamationTriangle className={"mr-2"} />
              {
                props.billingState.message ?  props.billingState.message : "Action required"
              }
            </H4>
        }
        {
          props.isLoadingUser ? <Skeleton count={4} /> :
              <p>
                Hey {props.name}, {props.subscriptionMsg ? props.subscriptionMsg : "there was a problem charging your default payment method. Click the button below to pay."}
              </p>
        }

        {
          props.isLoadingUser ? <Skeleton height={40} className={"my-6"}/> :
            <Filled_A
              href={props.billingState.invoiceUrl}
              className={"flex items-center"}
              target={"_blank"}
            >
              { props.subscriptionMsg ? "Resume subscription" : "Finish paying"}
            </Filled_A>
        }

      </BannerContainer>
    )
}
