import React, { useState } from "react"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import styled from "styled-components"
import tw from "tailwind.macro"
import {
  UseGlobalDispatchContext,
  UseGlobalStateContext,
} from "../../context/globalContext"
import { getTheme } from "../../themes/theme"
import { ErrorText } from "../../elements/shared/texts"
import AuthenticationService from "../../web_service/AuthenticationService"
import LoadingDots from "../../elements/shared/loaders/LoadingDots"
import StorageService from "../../helpers/StorageService"
import { BillingPeriods, GlobalActionsType } from "../../utils/constants";
import { DashboardActions, ResponseStatus } from "../../utils/constants.js"
import { ValidatedInput } from "../../elements/shared/inputs";

const CardContainer = styled.div`
  
  border: solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.deepBackground};
  border-width: ${props => props.theme.measurements.borderWidth} 0 ${props => props.theme.measurements.borderWidth} ${props => props.theme.measurements.borderWidth};
  padding: 15px;
  border-radius: 15px;
  width: 100%;
  #card-element {
    width: 100%;
  }

  @media (max-width: 768px) {
    border-radius: 15px;
    border-width: ${props => props.theme.measurements.borderWidth};
  }
`

const Form = styled("form")`
  ${tw`flex flex-col  `};
`

const PayButton = styled("button")`
  ${tw`focus:outline-none p-2  focus:shadow-outline`};
  width: auto;
  font-weight: bold;
  border-radius: 15px;
  margin-top: 20px;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.textInverse};
  
`

const CouponInput = styled.div`
  ${tw`flex items-center mt-4`}
  input {
    ${tw`p-2 w-full  focus:outline-none focus:shadow-outline`};
    background: transparent;
  }

  background: transparent;
  border-color: ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  border-width: ${props => props.theme.measurements.borderWidth};
  z-index: 20;
`

export const CheckoutForm = props => {
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState("")
  const [disabled, setDisabled] = useState(true)
  const [coupon, setCoupon] = useState("")
  const { currentTheme, userData } = UseGlobalStateContext()
  const dispatch = UseGlobalDispatchContext()
  const storage = StorageService.getService()
  const auth = AuthenticationService.getService()
  const stripe = useStripe()
  const elements = useElements()
  const theme = getTheme(currentTheme)

  const cardStyle = {
    style: {
      base: {
        color: theme.colors.primaryText,
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: theme.colors.gray,
        },
      },
      invalid: {
        color: theme.colors.cleanRed,
        iconColor: theme.colors.cleanRed,
      },
    },
  }

  const handleChange = async event => {
    setDisabled(event.empty)
    setError(event.error ? event.error.message : "")
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setProcessing(true)
    props.setProcessing(true)

    const cardElement = elements.getElement(CardElement)
    const priceId = props.priceId
    const customerId = props.customerId
    const billingPeriod = props.billingPeriod
    // If a previous payment was attempted, get the latest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      "latestInvoicePaymentIntentStatus"
    )


    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    })

    if (error) {
      setError(`createPaymentMethod failed ${error.message}`)
      setProcessing(false)
      props.setResponse({
        status: ResponseStatus.Failure,
        message: error.message,
      })
    } else {
      const paymentMethodId = paymentMethod.id

      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        const invoiceId = localStorage.getItem("latestInvoiceId")
        await retryInvoiceWithNewPaymentMethod({ customerId, paymentMethodId, invoiceId, priceId })
      } else {
        if (props.action === DashboardActions.UPDATE_PM) {
          await updatePaymentMethod(paymentMethodId)
        } else {
          await createSubscription({ customerId, paymentMethodId, priceId })
        }
      }
    }
  }

  async function updatePaymentMethod(paymentMethodId) {
    const data = {
      token: storage.getAccessToken(),
      pmId: paymentMethodId,
      cId: userData.stripeId
    }

    const siRes = await auth.updatePaymentMethod(data)
    const res = await stripe
      .confirmCardSetup(siRes.data.client_secret, {
        payment_method: paymentMethodId,
    })

    if (res.error) {
      handleError(res.error)
    } else {
      setProcessing(false)
      fetchUser("Your payment method has been successfully updated")
    }
  }

  async function createSubscription({ customerId, paymentMethodId, priceId }) {
    try {

      if (!customerId || !paymentMethodId) {
          handleError ({ message: "Unable to complete subscription. Make sure you've selected the plan and billing period." } )
      }

      const res = await auth.createSubscription({
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        billingPeriod: BillingPeriods.MONTHLY,
        couponId: coupon
      })

      const data = {
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        subscription: res.data,
      }

      const customerActionRes = await handlePaymentThatRequiresCustomerAction(data)
      const pmRes = handleRequiresPaymentMethod(customerActionRes)
      onSubscriptionComplete(pmRes)
    } catch (error) {
      handleError(error)
    }
  }

  async function retryInvoiceWithNewPaymentMethod({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId,
  }) {


    try {
      if (!customerId || !paymentMethodId || !priceId || !invoiceId) {
        handleError ({ message: "Oops! Something went wrong! Please, try again." }) ;
      }
      // return (
      const res = await auth.retryInvoice({
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        invoiceId: invoiceId,
      })
  
      const data = {
        invoice: res.data,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        isRetry: true,
      }
  
      const customerActionRes = await handlePaymentThatRequiresCustomerAction(data)
      
      onSubscriptionComplete(customerActionRes)
    
    } catch (error) {
        handleError(error)
    }
  }

  async function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
    error
  }) {

    if (error) {
      return  {error: error }
    } else if (subscription && subscription.status === "active") {
      return { subscription, priceId, paymentMethodId }
    }

    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      const res = await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId,
      })
      if (res.error) {
        return res
      } else {
                                          
        if (res.paymentIntent.status === "succeeded") {
         
          return {
            priceId: priceId,
            subscription: subscription,
            invoice: invoice,
            paymentMethodId: paymentMethodId,
          }
        }
      }
    } else {
      return { subscription, priceId, paymentMethodId }
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
    error
  }) {

    if (error) {
      return {error: error} 
    } else if (subscription.status === "active") {
      return { subscription, priceId, paymentMethodId }
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id)
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      )
      return { error: { message: "Your card was declined." } }
    } else {
      return { subscription, priceId, paymentMethodId }
    }
  }

  function fetchUser(message) {

    setTimeout(
      function() {
        auth.getUser(() => {
          const user = storage.getUser()
          dispatch({type: GlobalActionsType.USER, userData: user})
          setError(null)
          setProcessing(false)
          setSucceeded(true)
          props.setResponse({
            status: ResponseStatus.Success,
            message: message,
          })
        })
      }, 6000);
  }


  function onSubscriptionComplete(result) {

    if (result.error) {
      handleError(result.error)
    } else if (result.subscription) {
        fetchUser("Your subscription has been successful. Enjoy learning!")
    }

    localStorage.removeItem("latestInvoicePaymentIntentStatus")
    localStorage.removeItem("latestInvoiceId")
  }

  function handleError(error) {
    setError(error ? error.message : "")
    setProcessing(false)
    props.setResponse({
      status: ResponseStatus.Failure,
      message: error ? error.message : "",
    })
  }

  return (
    <div>
      <Form id="payment-form" onSubmit={handleSubmit}>
        <CardContainer>
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
        </CardContainer>
        {error && (
          <div className="card-error" role="alert">
            <ErrorText>{error}</ErrorText>
          </div>
        )}

        <small>If you have a coupon, don't mind the displayed price for your selected billing, the discount will applied automatically when you pay.</small>
        <CouponInput>
          <input type="text"
                 name={"couponId"}
                 placeholder={"Coupon (Optional)"}
                 onChange={e => setCoupon(e.target.value)}
          />
        </CouponInput>

        <PayButton disabled={processing || disabled || succeeded } id="submit">
          <span id="button-text">
            { processing ? <LoadingDots isBigger /> : <p> Subscribe </p>}
          </span>
        </PayButton>
        <br/>
      </Form>

    </div>
  )
}

