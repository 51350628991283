import styled, { css } from "styled-components"
import tw from "tailwind.macro"

export const AccountBillingContainer = styled.div`
  background-color: ${props => props.theme.colors.deepBackground};
  padding: 0 20px;
  border: ${props => props.theme.measurements.borderWidth} solid
    ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.measurements.borderRadius};

  ${props =>
    props.isCanceled &&
    css`
      sup {
        background-color: ${props =>
          props.theme.colors.lightClearRed} !important;
        color: ${props => props.theme.colors.cleanRed} !important;
      }
    `}

  sup {
    background-color: ${props => props.theme.colors.lightPrimary};
    padding: 2px 5px;
    border-radius: 10px;
    color: ${props => props.theme.colors.primary};
  }

  button,
  small {
    display: block;
  }

  small {
    color: ${props => props.theme.colors.gray};
  }

  & > div:first-of-type,
  & > div:last-of-type {
    ${tw``};
    padding: 20px 0;

    p {
      width: 70%;
      color: ${props => props.theme.colors.gray};
    }
  }

  & > div:first-of-type {
    & > div:last-of-type {
      ${tw`flex flex-col justify-start`}
    }
  }

  #payment {
  }
`

export const PaymentContainer = styled.div`
  ${tw`my-1 p-2`};
  width: min(100%, 500px);
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  svg {
    display: inline;

    line,
    polyline,
    path {
      color: ${props => props.theme.colors.primary};
    }
  }

  ${props =>
    props.isPaid &&
    css`
      background-color: transparent;

      a {
        color: ${props => props.theme.colors.primary};
      }
    `}

  ${props =>
    !props.isPaid &&
    css`
      
      background-color: ${props => props.theme.colors.lightClearRed};
      a {
        color: ${props => props.theme.colors.cleanRed};
      }
      svg {
        line,
        polyline,
        path {
          color: ${props => props.theme.colors.cleanRed};
        }
      }
    `}
          
          
   small:nth-of-type(3):hover {
    a {
      color: ${props => props.theme.colors.primary};
    }
    svg {
      line,
      polyline,
      path {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`
