import styled, { css } from "styled-components"


export const PlansCardContainer = styled.div`
  border: ${props => props.theme.measurements.borderWidth} solid ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.measurements.borderRadius};
  padding: 20px;

  ${props =>
  props.isSelected &&
  css`
      border-color: ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.lightPrimary};

    ${props => props.isPremium && css `
          border-color: ${props => props.theme.colors.accent};
          background-color: ${props => props.theme.colors.lightAccent};
    `}
  
  `}
  
  
  label {
    background: ${props => props.theme.colors.primary};
    padding: 5px 10px;
    border-radius: 5px;
    color: ${props => props.theme.colors.textInverse};
    font-weight: bold;

    ${props => props.isPremium && css `
          background-color: ${props => props.theme.colors.accent};
    `}
  }

  p:first-of-type {
    padding-top: 20px;
  }

  p:last-of-type {
    padding-top: 50px;
  }
`
