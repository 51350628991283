import React from "react"
import styled,{ css } from "styled-components"



export const ProcessingOverlayContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  ${props => props.isProcessing && css`
    -webkit-filter: blur(8px);
	  filter: blur(8px);
    `};
`