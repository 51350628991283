import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import PrivateRoute from "../components/PrivateRoute"
import Dashboard from "../components/DashboardComponent"
import { UseGlobalStateContext } from "../context/globalContext"
import { navigate } from "gatsby"
import { IsBrowser } from "../helpers/utils"
import StorageService from "../helpers/StorageService"

const Account = () => {
  const userData = StorageService.getService().getUser()

  if (!userData) {
    if (IsBrowser) {
      navigate("/auth")
    }
    return null
  }

  
  return (    
      <Router>
        <PrivateRoute path="/account/dashboard" component={Dashboard} />
      </Router>    
  )
}

export default Account