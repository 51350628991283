import React from "react"
import { navigate } from "gatsby"
import { UseGlobalStateContext } from "../context/globalContext"
import StorageService from "../helpers/StorageService"

const PrivateRoute = ({ component: Component, location, ...rest }) => {

  const userData = StorageService.getService().getUser()
  if (!userData) {
    navigate("/auth")
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute