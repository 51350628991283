import React from "react"
import styled, {tw} from "styled-components"
import { FaCcAmex, FaCcDiscover, FaCcJcb, FaCcMastercard, FaCcVisa, FaCcDinersClub} from "react-icons/fa";

const Container = styled.div`
  background-color: ${props => props.theme.colors.cleanGreen};
  padding: 20px;
  margin: 20px 0;
  width: min(100%, 300px);
  font-family: ${props => props.theme.fonts.code};
  border-radius: ${props => props.theme.measurements.borderRadius};
  background-image: url('https://image.ibb.co/bVnMrc/g3095.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: rgb(74, 74, 74);
  font-weight: bolder ;
  small, p {
    color: rgb(74, 74, 74) !important;
    font-weight: bolder ;
  }
  
  p{
    padding-left: 6px;
  }
`

const CreditCardBrand = props => {

  switch (props.brand) {
    case "amex":
      return <FaCcAmex size={props.size} />
    case "diners_club":
      return <FaCcDinersClub size={props.size} />;
    case "discover":
      return  <FaCcDiscover size={props.size} />
    case "jcb":
      return  <FaCcJcb size={props.size} />
    case "mastercard":
      return <FaCcMastercard size={props.size}  />
    case "cartes_bancaire":
      break;
    case "unionpay":
      return null;
    case "visa":
      return <FaCcVisa size={props.size} />
    default:
      return null;

  }
}

const CreditCardComponent = props => {

  return (
    <Container>
      <div id={"top"}>
          Main Card
      </div>
      <div id={"middle"} className={"py-4"}>
        **** **** **** { props.card ? props.card.Last4 : '0000' }
      </div>
      <div id={"bottom"} className={"flex items-center justify-between"}>
        <div id={"expireContainer"} className={"flex-grow "}>
          <small>Expires</small>
          <p>{props.card.ExpMonth}/{`${ props.card.ExpYear }`.substr(2)} </p>
        </div>
        <div id={"brand"}>
          <CreditCardBrand brand={props.card.Brand} size={40}/>
        </div>
      </div>
    </Container>
  )
}

export default CreditCardComponent