import styled, { css } from "styled-components"
import tw from "tailwind.macro"

export const BillingContainer = styled.div`
    ${tw`flex justify-between`}
     
    border: ${props => props.theme.measurements.borderWidth} solid ${props => props.theme.colors.border};
    border-radius: ${props => props.theme.measurements.borderRadius};
    padding: 20px;


    ${props =>
  props.isSelected &&
  css`
        border-color: ${props => props.theme.colors.primary};
        background-color: ${props => props.theme.colors.lightPrimary};
      `}
`