import React from "react"
import { PaymentComponent } from "./PaymentComponent"
import { H4 } from "../../elements/shared/texts"
import { NakedAccentButton } from "../../elements/shared/buttons";
import { BillingPeriods, GlobalActionsType, PricingPlans } from "../../utils/constants";
import { UseGlobalDispatchContext } from "../../context/globalContext";

export const CardComponent = props => {

  if (props.isLoadingUser) {
    return null;
  }

  const dispatch = UseGlobalDispatchContext()

  const showPrivacy = (e) => {
    dispatch({type: GlobalActionsType.PRIVACY, showPrivacy: true})
  }

  const showTerms = () => {
    dispatch({type: GlobalActionsType.TERMS, showTerms: true})
  }


  return (
    <div className="pt-8">
      <H4 className={"py-2"}>Payment method</H4>
      <PaymentComponent
        action={props.action}
        priceId={PricingPlans.SUPPORT}
        customerId={props.customerId}
        billingPeriod={BillingPeriods.MONTHLY}
        setResponse={props.setResponse}
        setProcessing={props.setProcessing}
      />
      <small >By subscribing, you also agree to our <NakedAccentButton onClick={() => { showPrivacy() }} >Privacy policy </NakedAccentButton> and<NakedAccentButton onClick={() => { showTerms() }} >Terms of service.</NakedAccentButton></small>

    </div>
  )
}
