import styled, { css } from "styled-components"
import tw from "tailwind.macro"

export const CheckIcon = styled.div`
    ${tw`flex items-center justify-center`};
    color: ${props => props.theme.colors.textInverse};
    border: ${props => props.theme.measurements.borderWidth} solid ${props =>
  props.theme.colors.border};
    width: 30px;
    height: 30px;
    border-radius: 15px;

    svg polyline {
        stroke: ${props => props.theme.colors.border};
    }
    ${props =>
      props.isSelected &&
      css`
        background-color: ${props => props.theme.colors.primary};
        border: none;

        svg polyline {
          stroke: ${props => props.theme.colors.textInverse};
        }

        ${props =>
          props.isPremium &&
          css`
            background-color: ${props => props.theme.colors.accent};
          `}
      `}
`
