import React from "react"
import styled, { css } from "styled-components"
import tw from "tailwind.macro"

export const BannerContainer = styled.div`
  ${tw`mb-8`};
  border: ${props => props.theme.measurements.borderWidth} solid
    ${props => props.theme.colors.cleanRed};
  border-radius: ${props => props.theme.measurements.borderRadius};
  padding: 20px 20px 0 20px;

  p:nth-of-type(1) {
    margin: 10px 0 40px 0;
    color: ${props => props.theme.colors.gray};
  }


  ${props => props.isSuccess && css``}

  ${props =>
    !props.isSuccess &&
    css`
      background-color: ${props => props.theme.colors.lightClearRed};
      color: ${props => props.theme.colors.cleanRed};
    `}
`
