import React, { useEffect, useState } from "react"
import Layout from "./Layout"
import WithLocation from "./WithLocation"
import ProcessLoading from "./ProcessLoading"
import ProfileHeader from "./ProfileHeader"
import {
  PricingPlans,
  BillingPeriods,
  DashboardActions,
} from "../utils/constants.js"
import { BodyText } from "../elements/shared/texts"
import { PrimaryButton } from "../elements/shared/buttons"
import StorageService from "../helpers/StorageService.js"
import AuthenticationService from "../web_service/AuthenticationService.js"
import { GlobalActionsType, ResponseStatus } from "../utils/constants.js"
import {
  UseGlobalDispatchContext,
  UseGlobalStateContext,
} from "../context/globalContext"
import { UseDashboardStateContext } from "../context/dashboardContext"
import {
  CardComponent,
  AccountDetails,
  BillingPeriod,
  PlanSelection,
  PersonalDetails,
  BillingErrorBanner,
} from "./dashboard"
import { IsBrowser } from "../helpers/utils"
import { navigate } from "@reach/router"
import { ProcessingOverlayContainer } from "./shared"
import { LoadingOverlay } from "../elements/shared/wrappers"
import { NarrowWrapper } from "./shared/NarrowWrapper"
import Skeleton from "react-loading-skeleton"
import { graphql, useStaticQuery } from "gatsby"



const DashboardComponent = ({ search }) => {
  const [plan, setPlan] = useState(PricingPlans.FREE)
  const [currentPlan, setCurrentPlan] = useState(PricingPlans.FREE)
  const [billing, setBilling] = useState(BillingPeriods.YEARLY)
  const [action, setAction] = useState("")
  const [nextPaymentDate, setNextPaymentDate] = useState(false)
  const [card, setCard] = useState(false)
  const [isCanceled, setCancelled] = useState(false)
  const [response, setResponse] = useState(null)
  const [isProcessing, setProcessing] = useState(false)
  const [isLoadingUser, setLoadingUser] = useState(false)
  const storage = StorageService.getService()
  const auth = AuthenticationService.getService()
  const { userData } = UseGlobalStateContext()
  const { session } = UseDashboardStateContext()
  const dispatch = UseGlobalDispatchContext()

  const pricingData = useStaticQuery(
    graphql`
        query {
            pricing: contentfulHomePricingJsonNode {
                premium {
                    monthly_price
                    yearly_price
                }
            }
        }
    `
  )

  useEffect(()=> {
    fetchUser()
  }, [])

  useEffect(() => {
    if (session.loading) {
      setProcessing(session.loading)
    }

    if (IsBrowser && session.data.url) {
      setProcessing(session.loading)
      navigate(session.data.url)

    }

    if (session.error) {
      setResponse({
        status: ResponseStatus.Failure,
        message: session.error
      })
    }
  }, [session])

  function fetchUser() {

      setLoadingUser(true)
      setTimeout(function () {
        auth.getUser(() => {
          const user = storage.getUser()
          setAction(false)
          dispatch({ type: GlobalActionsType.USER, userData: user })
        })
        setLoadingUser(false)
      }, 4500)
  }

  const changePlan = async () => {
    if (!userData || isProcessing) {
      return
    }

    setProcessing(true)
    const data = {
      cId: userData.stripeId,
      sId: userData.subscription.id,
      pId: plan,
      billingPeriod: billing,
    }

    const success = await auth.changePlan(data)
    if (success) {
      fetchUser()
      setResponse({
        status: ResponseStatus.Success,
        message:
          "Your plan has been successfully updated. Enjoy! It might take a few seconds for the changes to be reflected on the dashboard.",
      })
    }
  }

  const cancelSubscription = async event => {
    if (!userData || isProcessing) {
      return
    }
    setProcessing(true)
    const data = {
      sId: userData.subscription.id,
    }
    const success = await auth.cancelSubscription(data)
    if (success) {
      fetchUser()
      setResponse({
        status: ResponseStatus.Success,
        message:
          "It's sad to see you leave the premium plan. Hope you change your mind or tell us what you didn't like here contact@liquidcoder.com. It might take a few seconds for the changes to be reflected on the dashboard.",
      })
    }
  }

  const restartSubscription = async event => {
    if (!userData || isProcessing) {
      return
    }
    setProcessing(true)
    const data = {
      sId: userData.subscription.id,
      priceId: userData.subscription.priceId,
    }

    const success = await auth.restartSubscription(data)
    if (success) {
      fetchUser()
      setResponse({
        status: ResponseStatus.Success,
        message:
          "It's a great pleasure to have you back. Your subscription has been successfully restarted. Thank you! It might take a few seconds for the changes to be reflected on the dashboard.",
      })
    }
  }

  const changeAction = action => {
    setAction(action)
  }

  React.useEffect(() => {
    const user = storage.getUser()
    dispatch({ type: GlobalActionsType.USER, userData: user })

    if (!userData) {
      return
    }

    setBilling(userData.subscription.billingPeriod ? userData.subscription.billingPeriod : BillingPeriods.YEARLY )
    setCurrentPlan(userData.subscription.plan)
    setNextPaymentDate(userData.subscription.currentPeriodEnd)
    setCard(userData.subscription.paymentMethod)
    setCancelled(userData.subscription.cancelAtPeriodEnd)
  }, [userData])

  if (!userData) {
    return <h1>Login required!</h1>
  }

  return (
    <Layout>
      {isProcessing &&
      <LoadingOverlay id={"loadingOverlay"} onClick={(e) => {
        if (e.target.parentElement.id === "loadingOverlay") {
          if (response.status === ResponseStatus.Success) {
            setAction(false)
            fetchUser()
          }
          setProcessing(false)
          setResponse(null)
        }
      }}>
        <ProcessLoading
          id={"processLoading"}
          response={response}
          setResponse={setResponse}
          setProcessing={setProcessing}
          onClose={() => {
            if (response.status === ResponseStatus.Success) {
              setAction(false)
              fetchUser()
            }

          }}
        />
      </LoadingOverlay>
      }
      <ProcessingOverlayContainer isProcessing={isProcessing}>
      <BodyText>
        <NarrowWrapper >
          <div className={"container mx-auto  relative"}>


              <ProfileHeader
                content={{
                  title: "Dashboard",
                  description:
                    "Welcome to your dashboard, the place where you will be able to manage your personal and subscription details",
                }}
              />
            <BillingErrorBanner billingState={userData.billingState}
                                name={userData.firstName}
                                subscriptionMsg={userData.subscription.message}
                                isLoadingUser={isLoadingUser}
            />

            <PersonalDetails
              firstName={userData.firstName}
              lastName={userData.lastName}
              email={userData.email}
              isLoadingUser={isLoadingUser}
            />


            {
              (action !== DashboardActions.SUBSCRIBE &&
                action !== DashboardActions.CHANGE_PLAN) &&
              <AccountDetails
                isProcessing={isProcessing}
                isPremium={userData.isPremium}
                setAction={changeAction}
                card={card}
                nextPaymentDate={nextPaymentDate}
                latestPayments={userData.latestPayments}
                cancelSubscription={cancelSubscription}
                restartSubscription={restartSubscription}
                isCancelled={isCanceled}
                currentPlan={userData.subscription.plan}
                user={userData}
                cancelAtPeriodEnd={userData.subscription.cancelAtPeriodEnd}
                isLoadingUser={isLoadingUser}
              />
            }

              {/*{(action === DashboardActions.SUBSCRIBE ||*/}
              {/*  action === DashboardActions.CHANGE_PLAN) && (*/}
              {/*  <div>*/}
              {/*    <PlanSelection*/}
              {/*      billing={billing}*/}
              {/*      action={action}*/}
              {/*      plan={plan}*/}
              {/*      setPlan={setPlan}*/}
              {/*      pricing={pricingData.pricing.premium}*/}
              {/*      isLoadingUser={isLoadingUser}*/}
              {/*    />*/}
              {/*    <BillingPeriod*/}
              {/*      plan={plan}*/}
              {/*      billing={billing}*/}
              {/*      setBilling={setBilling}*/}
              {/*      pricing={pricingData.pricing.premium}*/}
              {/*      isLoadingUser={isLoadingUser}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*)}*/}

              {action === DashboardActions.CHANGE_PLAN && (
                <PrimaryButton
                  onClick={event => changePlan()}
                  className="sm:self-end self-start mt-4"
                >
                  Change plan
                </PrimaryButton>
              )}

              {action === DashboardActions.SUBSCRIBE && (
                <CardComponent
                  action={action}
                  plan={plan}
                  currentPlan={currentPlan}
                  customerId={userData.stripeId}
                  billingPeriod={billing}
                  setResponse={setResponse}
                  setProcessing={setProcessing}
                  isLoadingUser={isLoadingUser}
                />
              )}

          </div>
        </NarrowWrapper>
      </BodyText>
      </ProcessingOverlayContainer>
    </Layout>
  )
}

export default WithLocation(DashboardComponent)
